import useDaynavStore from "../../data/Store";

import './DetailTitle.css';
import { MdKeyboardBackspace, MdLocationOn, MdOutlineArrowDownward, MdOutlineArrowUpward, MdRoute } from "react-icons/md";
import { SelectionType } from "../../data/Data";

function DetailTitle() {
    const [stepSelection, selectStep, trip] = [useDaynavStore(state => state.stepSelection), useDaynavStore(state => state.selectStep), useDaynavStore(state => state.trip)];

    const back = () => {
        selectStep({ step: -1, type: SelectionType.Step })
    }

    const up = () => {
        if (stepSelection.step > 0 && stepSelection.type === SelectionType.Step) {
            selectStep({ step: stepSelection.step, type: SelectionType.Way })
        }
        if (stepSelection.step > 0 && stepSelection.type === SelectionType.Way) {
            selectStep({ step: stepSelection.step - 1, type: SelectionType.Step })
        }
    }

    const down = () => {
        if (stepSelection.step <= trip.steps.length - 1 && stepSelection.type === SelectionType.Way) {
            selectStep({ step: stepSelection.step, type: SelectionType.Step })
        }
        if (stepSelection.step < trip.steps.length - 1 && stepSelection.type === SelectionType.Step) {
            selectStep({ step: stepSelection.step + 1, type: SelectionType.Way })
        }
    }


    return (
        <div className="DetailTitle">
            <div>
                <div
                    className="icon"
                    onClick={back}>
                    <MdKeyboardBackspace size={20} />
                </div>
            </div>
            <div>
                <div
                    className="icon up"
                    onClick={up}>
                    <MdOutlineArrowUpward size={20} />
                </div>
                <div
                    className="text">
                    <div>
                        {stepSelection.step + 1}{" "}
                        {stepSelection.type === SelectionType.Step ? <MdLocationOn size={26} /> : <MdRoute size={26} />}
                    </div>
                </div>
                <div
                    className="icon down"
                    onClick={down}>
                    <MdOutlineArrowDownward size={20} />
                </div>
            </div>
        </div>
    );
}
export default DetailTitle;