import { CiLink } from "react-icons/ci";
import { StepModel } from "../../../data/Data";
import { stepToLink } from "../../../services/mapService";

interface LinksProps {
    step: StepModel
}

function Links(props: LinksProps) {
    const step = props.step;
  
  
    if (step.website) {
      return (
        <div className="links">
          <h2>Links</h2>
          <ul>
            <li><a href={stepToLink(step)} target="_blank" rel="noreferrer"><CiLink /> Open in Google Maps</a></li>
            <li><a href={step.website} target="_blank" rel="noreferrer"><CiLink /> Website</a></li>
           
          </ul>
        </div>
      )
    }
    return (
      <></>
    )
   
  }
  export default Links;