import React, { useEffect, useState } from 'react'



export default function Photo(props) {
    const [photo, setPhoto] = useState('')

    useEffect(() => {
      if (props.step?.photos?.length > 0) {
        setPhoto('url(' + props.step.photos[0] + ')')
      }
    }, [props.step.photos])
    
    return (
      <div className="image" style={{'backgroundImage': photo}} onClick={props.onClick}></div>
    )
}
