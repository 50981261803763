import React, { useState } from 'react'
import { Container, Nav, Navbar, Offcanvas } from 'react-bootstrap'
import useDaynavStore from '../../data/Store';

import './TopNav.css'

export default function TopNav() {
    const history = useDaynavStore(state => state.history)
    const createBlank = useDaynavStore(state => state.createBlank)
    const selectFromHistory = useDaynavStore(state => state.selectFromHistory)
  
    const [showMenu, setShowMenu] = useState(false);
  
    const select = (elem) => {
      selectFromHistory(elem)
      setShowMenu(false)
    }
    const create = (elem) => {
      createBlank(elem)
      setShowMenu(false)
    }
    return (
        <Navbar expand={false} className="TopNav sticky-top">
            <Container fluid>
                <Navbar.Brand>
                    <img
                    alt=""
                    src="/daynav.png"
                    height="40"
                    className="d-inline-block align-top"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-sm`} onClick={() => setShowMenu(!showMenu)}/>
                <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-sm`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
                    placement="end"
                    show={showMenu}
                    onHide={() => setShowMenu(false)}
                    >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`}>
                        Settings
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3">
                        <h3>History</h3>
                        {history.map((element, i) => (
                            <Nav.Link key={i} onClick={() => select(i)}>{element.name}</Nav.Link>
                        ))}
                        <hr />
                        <Nav.Link onClick={() => create()}>Create new trip</Nav.Link>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    )
}
