import React, { useEffect, useState } from 'react'
import useDaynavStore from '../../data/Store';
import './TripTitel.css'

import { CiEdit, CiShare1 } from 'react-icons/ci';
import { Button, Form, InputGroup, Modal, Spinner } from 'react-bootstrap';
import { shareTrip } from '../../services/shareService';
import { SelectionType } from '../../data/Data';



export default function TripTitel() {
    const [trip, updateName] = [useDaynavStore(state => state.trip), useDaynavStore(state => state.updateName)];
    const [stepSelection, selectStep] = [useDaynavStore(state => state.stepSelection), useDaynavStore(state => state.selectStep)];
    const reset = useDaynavStore(state => state.reset);

    const [showEdit, setShowEdit] = useState(false);
    const [name, setName] = useState();

    const [showShare, setShowShare] = useState(false);
    const [shareUrl, setShareUrl] = useState();
    const [shared, setShared] = useState(false);

    useEffect(
        () => {    
            setName(trip.name)
        },
        [trip, trip.steps],
    )

    useEffect(
        () => {    
            setShared(false)
            setShareUrl(null)
            if (showShare) {
                console.log('save to server')
                shareTrip(trip, result => {
                    setShareUrl(result)
                    console.log('saved trip', result)
                })
            }
        },
        [showShare, trip],
    )

    const deleteTrip = () => {
        setShowEdit(false);
        reset();
    }
    
    const saveChanges = () => {
        setShowEdit(false);
        updateName(name)
    }

    const copyToClipboard = async (text) => {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        }
    }

    const copyUrl = () => {
        copyToClipboard(shareUrl).then(() => setShared(true) );
    }

    return (
        <>
        <div className={stepSelection.step === -1 ? "TripTitel selected" : "TripTitel"} onClick={() => selectStep({step: -1, type: SelectionType.Step})}>
            <div>
                <h2>{trip.pretitle}</h2>
                <h1>
                {trip.name}
                <div className="icon" onClick={() => setShowEdit(true)}>
                    <CiEdit size={22} style={{ fill: '#000' }} />
                </div>
                </h1>
                <p>{trip.description}</p>
                <div className='tips'>
                    <h3>Accomodations:</h3>
                    <p>{trip.tips?.accommodations}</p>
                    <h3>Car Rental:</h3>
                    <p>{trip.tips?.car_rental}</p>
                    <h3>Gear:</h3>
                    <p>{trip.tips?.gear}</p>
                </div>
            </div>
            
            <div className="share" onClick={() => setShowShare(true)}>
                <CiShare1 size={30} style={{ fill: '#000' }} />
            </div>
        </div>

        <Modal show={showEdit} onHide={() => setShowEdit(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Trip base settings</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="formName">
                        <Form.Label>Trip name</Form.Label>
                        <Form.Control type="name" placeholder="Enter name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                         />
                    </Form.Group>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="danger" onClick={deleteTrip}>Clear trip</Button>
                <Button variant="primary" onClick={saveChanges}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={showShare} onHide={() => setShowShare(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Share Trip {trip.name}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="formName">
                        <div className={shareUrl ? "showurl" : "showspinner"}>
                        <Form.Label>Trip URL:</Form.Label>
                        <div className="spinner">
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                        <InputGroup className="mb-3 url">
                            <Form.Control
                                value={shareUrl}
                            />
                            <Button variant={shared ? "success" : "primary"} id="button-addon2" onClick={copyUrl}>
                                Copy
                            </Button>
                        </InputGroup>
                        <p>{shared ? "URL copied to clipboard" : ""}&nbsp;</p>
                         </div>
                    </Form.Group>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowShare(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    )
}
