import { Carousel } from "rsuite";
import { StepModel } from "../../../data/Data";

interface PhotosProps {
    step: StepModel
}

function Photos(props: PhotosProps) {
    const step = props.step;
    if (step.photos) {
      return (
        <div className="photos">
           <Carousel autoplay className="custom-slider">
            {step.photos.map((photo, i) => {
                return (
                  <div key={i}>
                  <img
                    className=""
                    src={photo}
                    alt="."
                  />
                  </div>
                )
              })}
          </Carousel>
  
        </div>
      )
    }
    return (
      <></>
    )
}
export default Photos;