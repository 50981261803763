import { useEffect } from "react";
import useDaynavStore from "../../data/Store";
import TripTitel from "../title/TripTitel";

import './Headline.css'
import DetailTitle from "../detail/DetailTitle";

function Headline() {

    const [stepSelection, selectStep] = [useDaynavStore(state => state.stepSelection), useDaynavStore(state => state.selectStep)];

    useEffect(
        () => {
            console.log("Headline: useEffect: stepSelection: ", stepSelection)
        },
        [stepSelection, selectStep],
    )

    if (stepSelection.step === -1) return (<div className="Headline"><TripTitel /></div>)

    return (<div className="Headline"><DetailTitle /></div>)
}
export default Headline;