import './Home.css'

function Home() {

    return (
        <div className='Home'>
            <img
            alt=""
            src="/daynav.png"
            height="90"
            className="d-inline-block align-top"
            />
        </div>
    ) 
}

export default Home;