import { StepModel } from "../../../data/Data";

interface OpeningsProps {
    step: StepModel
}

function Openings(props: OpeningsProps) {
    const step = props.step;
  
  
    if (step.opening_hours) {
      return (
        <div className="openinghours">
          <h2>Opening hours</h2>
          <ul>
            
            {step && step.opening_hours && step.opening_hours.weekday_text ? step.opening_hours.weekday_text.map((text, i) => {
              return (<li key={i}>{text}</li>)
            }) : <li>-</li>}
          </ul>
        </div>
      )
    }
    return (
      <></>
    )
   
  }
  export default Openings;