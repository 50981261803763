import { useJsApiLoader, LoadScriptProps } from "@react-google-maps/api";
import useDaynavStore from "../../data/Store";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import TripSchedule from "../../components/tripschedule/TripSchedule";
import TripMap from "../../components/map/TripMap";

import './TripEditor.css'
import Headline from "../../components/headline/Headline";
import { setTitle } from "../../services/pageServices";
import NewTripAssistant from "./NewTripAssistant";
import TopNav from "../../components/topnav/TopNav";

function TripEditor() {
    const trip = useDaynavStore(state => state.trip);

    const googleMapsLibraries: LoadScriptProps['libraries'] = ["places"];

    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API || '',
        libraries:  googleMapsLibraries
    });

    useEffect(
        () => {    
            setTitle(trip)
        },
        [trip, trip.name],
    )
    
    return isLoaded && trip.steps ? (
        <>
            <TopNav />
            <Container fluid>
                <Row>
                    <Col sm className="map">
                        <TripMap />
                    </Col>
                    <Col sm>
                        <Headline />
                        { /* <TimeSchedule /> */}
                        <TripSchedule />
                    </Col>
                </Row>
            </Container>
            <NewTripAssistant />
        </>
    ) : (<></>)
}

export default TripEditor;