import moment from "moment";

const getTripDateBoundaries = (trip) => {
    try {
        if (trip.steps.length > 0) {
            const firstStep = trip.steps[0];
            const lastStep = trip.steps[trip.steps.length-1];
            if (firstStep.datetime && lastStep.datetime) {
                const start = moment(firstStep.datetime);
                const end = moment(lastStep.datetime);
                return {start, end};
            }
        }
    } catch (error) {
        console.log('can\'t calculate trip date boudaries', error);
    }
    return {start: null, end: null};
}


/*
const getMinDeparture = (trip, position) => {
    try {
        if (position >= 1 && trip.steps[position-1].datetime) {
            const fromStep = trip.steps[position-1];
            const min = moment(fromStep.datetime);
            if (fromStep.departure && fromStep.route?.duration) {
                min.add(fromStep.route.duration, 'seconds')
            }
            return min;
        }
    } catch (error) {
        console.log('can\'t calculate min departure for position [' + position  + ']', error);
    }
    return null;
}*/

/**
 * 
 * @param {*} trip 
 * @param {*} position 
 * @returns 
 */
const getArrival = (trip, position) => {
    try {
        if (position >= 0 && trip.steps[position].datetime) {
            const fromStep = trip.steps[position];
            const min = moment(fromStep.datetime);
            return min;
        }
    } catch (error) {
        console.log('can\'t calculate arrival for position [' + position  + ']', error);
    }
    return null;
}

const getDeparture = (trip, position) => {
    try {
        if (position >= 0 && trip.steps[position+1] && trip.steps[position+1].datetime) {
            const fromStep = trip.steps[position+1];
            const min = moment(fromStep.datetime);
            return min;
        }
    } catch (error) {
        console.log('can\'t calculare min departure for position', position, error);
    }
    return null;
}

const formatDuration = (duration) => {
    return (Math.round(duration / 60 / 6) / 10) + "h"
}

const formatDistance = (distance) => {
    return Math.round(distance / 1000) + "km"
}

export {getTripDateBoundaries, getArrival, getDeparture, formatDuration, formatDistance}