import React, { useEffect, useRef, useState } from 'react';
import useDaynavStore from '../../data/Store';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Alert, Form, Spinner } from 'react-bootstrap';


export default function NewTripAssistant() {
    const [trip] = [useDaynavStore(state => state.trip)];
    const [setAssistantData] = [useDaynavStore(state => state.setAssistantData)];
    const [showFromToModal, setShowFromToModal] = useState(false);
    const handleClose = () => setShowFromToModal(false);
    const modal = useRef(null);

    const [error, setError] = useState(false);

    const [loading, setLoading] = useState(false)

    const [start, setStart] = useState();
    const [stop, setStop] = useState();
    const [days, setDays] = useState();
    const [purpose, setPurpose] = useState();
    const [mustVisits, setMustVisits] = useState();

    /*const [start, setStart] = useState('Los Angeles');
    const [stop, setStop] = useState('Denver');
    const [days, setDays] = useState(20);
    const [purpose, setPurpose] = useState('sighseeing');
    const [mustVisits, setMustVisits] = useState('Grand Canyon, Monument Valley');*/

    useEffect(
        () => {
            if (trip.steps.length < 1) {
                setShowFromToModal(true);
            } else {

                setShowFromToModal(false);
            }
        },
        [trip]
    );


    const save = () => {
        if (!start || !stop || !days || !purpose || !mustVisits) {
            setError(true)
            return
        }
        setError(false)

        const recipeUrl = 'https://trvlapp.azurewebsites.net/api/recommend';
        //const recipeUrl = 'http://localhost:7071/api/recommend';

        const postBody = {
            "start": start, 
            "stop": stop, 
            "days": days, 
            "purpose": purpose, 
            "must_visits": mustVisits.split(';'), 
            "dev": ''
        };
        
        const headers =  {
            "accept": "*/*",
            "accept-language": "en,en-US;q=0.9,de-DE;q=0.8,de;q=0.7",
            "cache-control": "no-cache",
            //"content-type": "application/x-www-form-urlencoded; charset=UTF-8",
            'Content-Type': 'application/json',
            "pragma": "no-cache"
        };
        let dataString = JSON.stringify(postBody)
        setLoading(true)
        fetch(recipeUrl,  {
            "headers": headers,
            "referrerPolicy": "strict-origin-when-cross-origin",
            "method": "POST",
            "body": dataString,
            "mode": "cors",
            "credentials": "omit"
        })
        .then(res => res.json())
        .then(data => {
            console.log('receive assistantData', data)
            setLoading(false)
            setAssistantData(data)
        });
    };


    return (
        <Modal ref={modal} show={showFromToModal} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>New Trip Assistant</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert key='danger' variant='danger' show={error}>
                Please enter all values
                </Alert>

                { (loading) ? 
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner> : 
                
                <Form>
                    <Form.Group className="mb-3" controlId="formName">
                        <Form.Label>Start</Form.Label>
                        <Form.Control type="start" placeholder="Enter start"
                            value={start}
                            onChange={(e) => setStart(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formName">
                        <Form.Label>Stop</Form.Label>
                        <Form.Control type="stop" placeholder="Enter stop"
                            value={stop}
                            onChange={(e) => setStop(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formName">
                        <Form.Label>Days</Form.Label>
                        <Form.Control type="days" placeholder="Enter days"
                            value={days}
                            onChange={(e) => setDays(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formName">
                        <Form.Label>Purpose</Form.Label>
                        <Form.Control type="purpose" placeholder="Enter purpose"
                            value={purpose}
                            onChange={(e) => setPurpose(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formName">
                        <Form.Label>Must visits (semicilon ";" separated)</Form.Label>
                        <Form.Control type="mustVisits" placeholder="Enter must visits"
                            value={mustVisits}
                            onChange={(e) => setMustVisits(e.target.value)} />
                    </Form.Group>


                </Form>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={save}>
                    Start
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
