import React, { useEffect } from 'react'
import { GoogleMap } from '@react-google-maps/api';
import useDaynavStore from '../../data/Store';
import { fixBounds, toLatLng, tripToBounds, updateRoutes, updateTripDetails } from '../../services/mapService';

import './TripMap.css'
import { SelectionType } from '../../data/Data';

const containerStyle = {
  width: '100%',
  height: '100%' //'calc(50vh - 50px - 66px)'
};

const center = {
  lat: -3.745,
  lng: -38.523
};

export default function TripMap() {
  const [updatePause, setUpdatePause] = React.useState(true)
  const [trip] = [useDaynavStore(state => state.trip)];
  const [load] = [useDaynavStore(state => state.load)];
  const [assistantData, setAssistantData] = [useDaynavStore(state => state.assistantData), useDaynavStore(state => state.setAssistantData)];
  const [stepSelection, selectStep] = [useDaynavStore(state => state.stepSelection), useDaynavStore(state => state.selectStep)]
  const [updateStep] = [useDaynavStore(state => state.updateStep)]

  const [map, setMap] = React.useState(null)
  const [mapElements, setMapElements] = React.useState([])

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map)
    //window.google.maps.event.addListener(map, "click", () => selectStep({step: -1, type: SelectionType.Step}));
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  useEffect(() => {
    if(map) {
      try {
        if (stepSelection.step === -1) {
          map.fitBounds(tripToBounds(trip));
        } else if (trip.steps[stepSelection.step]?.route?.bounds && stepSelection.type === SelectionType.Way) {
          map.fitBounds(fixBounds(trip.steps[stepSelection.step]?.route?.bounds));
        } else if (trip.steps[stepSelection.step]?.geometry?.viewport) {
          map.fitBounds(fixBounds(trip.steps[stepSelection.step]?.geometry?.viewport));     
        }
      } catch (error) {
        console.log('move map to trip error', error)
      }
    }
  
  }, [trip, stepSelection, map])

  useEffect(
    () => {    
      if (map && !updatePause) {
        updateTripDetails(map, trip, updateStep)
        updateRoutes(map, trip, updateStep)
        pauseUpdates()
      }
   },
   [trip, trip.steps, map, updateStep, updatePause],
 )

 const pauseUpdates = () => {      
  setUpdatePause(true)
  setTimeout(() => {
    console.log('deactivate update pause')
    setUpdatePause(false)
  }, 10000)
 }

 useEffect(
  () => {    
    if (map && assistantData && assistantData.title) {
      pauseUpdates()
      console.log('build trip from assistantData', assistantData)
      let trip = {
          pretitle: assistantData.pretitle, 
          name: assistantData.title,
          steps: [], 
          description: assistantData.description, 
          tips: assistantData.tips
      }
      assistantData.route.forEach(route => {
        trip.steps.push({'name': 'new'})
      })

      load(trip)
      setAssistantData({})

      //let steps = []
      const service = new window.google.maps.places.PlacesService(map);

      assistantData.route.forEach((route, position) => {

        service.textSearch( { query: route.location }, places => {
          console.log('gd', route.location, places)
          let place = places[0]
          if (!place || !place.formatted_address) return;
          var s = {
            name: place.formatted_address, 
            description: '', 
            place_id: place.place_id, 
            text: `${route.activities.join(',\n ')} \n\n day: ${route.day} \n\n Assistant Location: ${route.location}`,
            geometry: {location: [place.geometry.location.lat(), place.geometry.location.lng()] }, 
            details: Date.now(),
            activities: route.activities
          }
          if (place.photos) {
              s.photos = place.photos.map(photo => photo.getUrl());
          }
          if (place.url) {
              s.url = place.url;
          }
          if (place.website) {
              s.website = place.website;
          }
          if (place.types) {
              s.types = place.types;
          }
          if (place.opening_hours) {
              s.opening_hours = {
                  periods: place.opening_hours.periods, 
                  weekday_text: place.opening_hours.weekday_text, 
              }
          }
          console.log('add step at', position, s)
          updateStep(position, s)
        })
      })
    }
 },
 [assistantData, load, map, updateStep, setAssistantData],
)

 const svgMarker = {
  path: "M31.62,21.55c0,5.3-4.3,9.6-9.6,9.6c-5.3,0-9.6-4.3-9.6-9.6c0-5.3,4.3-9.6,9.6-9.6 C27.32,11.97,31.61,16.26,31.62,21.55 M42.89,22.2c0.08-11.54-9.21-20.96-20.75-21.04S1.18,10.37,1.1,21.91c0,0.1,0,0.19,0,0.29 c0,11.54,17.2,26.53,19.25,47.02c0.01,0.9,0.75,1.63,1.65,1.62c0.01,0,0.01,0,0.02,0c0.9-0.04,1.63-0.77,1.67-1.67v-0.11 C26.12,48.57,42.9,33.69,42.89,22.2",
  fillColor: '#666',
  fillOpacity: 1,
  strokeWeight: 0,
  rotation: 0,
  scale: 0.4,
  anchor: new window.google.maps.Point(22, 72),
};

const svgMarkerAct = {
  path: "M31.62,21.55c0,5.3-4.3,9.6-9.6,9.6c-5.3,0-9.6-4.3-9.6-9.6c0-5.3,4.3-9.6,9.6-9.6 C27.32,11.97,31.61,16.26,31.62,21.55 M42.89,22.2c0.08-11.54-9.21-20.96-20.75-21.04S1.18,10.37,1.1,21.91c0,0.1,0,0.19,0,0.29 c0,11.54,17.2,26.53,19.25,47.02c0.01,0.9,0.75,1.63,1.65,1.62c0.01,0,0.01,0,0.02,0c0.9-0.04,1.63-0.77,1.67-1.67v-0.11 C26.12,48.57,42.9,33.69,42.89,22.2",
  fillColor: '#000',
  fillOpacity: 1,
  strokeWeight: 0,
  rotation: 0,
  scale: 0.4,
  anchor: new window.google.maps.Point(22, 72),
};


 useEffect(
  () => {    
    if (map) {
      mapElements.forEach(polyline => {
        polyline.setVisible(false)
        polyline.setMap(null)
      })
      trip.steps.forEach((step, i) => {
        if (step.geometry?.location) {
          const marker = new window.google.maps.Marker({
            position: toLatLng(step),
            icon: (i === stepSelection.step && stepSelection.type === SelectionType.Step) ? svgMarkerAct : svgMarker,
            map,
            title: step.name,
            animation: (i === stepSelection.step && stepSelection.type === SelectionType.Step) ? window.google.maps.Animation.BOUNCE : null
          });
          window.google.maps.event.addListener(marker, "click", () => selectStep({step: i, type: SelectionType.Step}));
          marker.setMap(map);
          mapElements.push(marker)
        }
        if (step.route?.path && step.route?.path.length > 1) {
          var polyline = new window.google.maps.Polyline({
              path: step.route?.path,
              strokeColor: (i === stepSelection.step && stepSelection.type === SelectionType.Way) ? '#000' : '#666',
              strokeOpacity: 1,
              strokeWeight: i === stepSelection.step ? 4 : 2, 
              zIndex: i === stepSelection.step ? 5 : 4,
          });
          window.google.maps.event.addListener(polyline, "click", () => selectStep({step: i, type: SelectionType.Way}));
          polyline.setMap(map);
          mapElements.push(polyline)
          setMapElements(mapElements)
        }
      })
    }
 },
 // eslint-disable-next-line react-hooks/exhaustive-deps
 [trip, trip.steps, stepSelection, map],
)

  return  (
      <div className="TripMap">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={10}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={{mapTypeControl: false, streetViewControl: false, fullscreenControl: false}}
        >
        </GoogleMap>
      </div>
  ) 
}
