
interface TripModel {
    pretitle?: string, 
    name: string, 
    steps: Array<StepModel>, 
    description?: string,
    tips?: [string] 
}

interface OpeningHoursModel {
    weekday_text: [string]
}

interface StepModel {
    name: string, 
    place_id?: string, 
    description?: string, 
    geometry?: object | null,
    route?: any | null,
    photos?: [string] | null,
    opening_hours?: OpeningHoursModel | null,
    website?: any | null,
    text?: string | null,
    activities?: [string]
}

enum SelectionType {
    Step, Way
}

interface StepSelectionModel {
    
    step: number, 
    type: SelectionType
}

export type {TripModel, StepModel, StepSelectionModel};
export {SelectionType}