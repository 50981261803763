import React from 'react';
import { createRoot } from 'react-dom/client';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'rsuite/dist/rsuite-no-reset.min.css';
import './index.css';
import App from './app/App';
import { BrowserRouter } from "react-router-dom";

const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement);
root.render(<BrowserRouter>
  <React.StrictMode>
  <App />
  </React.StrictMode>
</BrowserRouter>);

