import React from "react";
import { MdDirectionsCar } from "react-icons/md";
import { SelectionType, StepModel } from "../../data/Data";
import useDaynavStore from "../../data/Store";
import WayDetail from "../detail/WayDetail";
import {
  formatDistance,
  formatDuration,
} from "../../services/travelTimeServices";

interface WayProps {
  step: StepModel;
  position: number;
}

export default function Way(props:WayProps) {

  const { position, step } = props;



  const [stepSelection, selectStep] = [
    useDaynavStore((state) => state.stepSelection),
    useDaynavStore((state) => state.selectStep),
  ];

  const select = () => {
    if (stepSelection.step === position && stepSelection.type === SelectionType.Way) {
      selectStep({ step: -1, type: SelectionType.Way });
    } else {
      selectStep({ step: position, type: SelectionType.Way });
    }
  };


  if (position === 0) return <></>;

  if (!step.route) return <></>;

  if (step.route.distance === 0) return <></>;

  
  return (
    <div
      className={
        position === stepSelection.step && stepSelection.type === SelectionType.Way
          ? "schedule-item way selected"
          : "schedule-item way"
      }
    >
      <div className="lmgrid">
        <div className="icon" onClick={select}>
          <MdDirectionsCar size={23} style={{ fill: "#000" }} />
        </div>
        <div className="line" />
        <div className="text" onClick={select}>

        <h2>{step.route.summary}</h2>
        <p> {" "}

          {formatDistance(step.route.distance)},{" "}
          {formatDuration(step.route.duration)}
          </p>
        </div>
        <WayDetail
          step={step}
          position={position}
          selected={
            position === stepSelection.step && stepSelection.type === SelectionType.Way
          }
        />
      </div>
    </div>
  );
}
