import React from "react";
import { ListGroup } from "react-bootstrap";

import useDaynavStore from "../../data/Store";
import "./TripSchedule.css";
import AddButton from "../addbutton/AddButton";
import Add from "../add/Add";

import Step from "./Step";
import Way from "./Way";
import { SelectionType } from "../../data/Data";


export default function TripSchedule() {
  const [trip] = [useDaynavStore((state) => state.trip)];
  const [stepSelection] = [
    useDaynavStore((state) => state.stepSelection),
  ];

  const display = (position)  => {
    if (position === 0) return 'start';
    if (position === trip.steps.length - 1) return 'end';
    return 'standard';
  }

  return (
    <>
      <div
        className={
          stepSelection.step === -1 ? "TripSchedule all" : "TripSchedule limited"
        }
      >
        
        <div className="TripSchedule-list">
          {trip.steps.map((step, i) => { 
            if (!step || !step.place_id)
              return <UninicializedStep step={step} position={i} key={i} />;
            return (
              <React.Fragment key={"d" + i}>
                <Way step={step} position={i} key={"w" + i} />
                <Step step={step} position={i} key={"s" + i} display={display(i)} />
              </React.Fragment>
            );
          })}
        </div>
        <AddButton />
      </div>
    </>
  );
}

function UninicializedStep(props) {
  const [stepSelection, selectStep] = [
    useDaynavStore((state) => state.stepSelection),
    useDaynavStore((state) => state.selectStep),
  ];

  return (
    <ListGroup.Item
      className={
        props.position === stepSelection.step ? "empty selected" : "empty"
      }
      onClick={() => {
        selectStep({ step: props.position, type: SelectionType.Step });
      }}
    >
      <Add position={props.position} />
    </ListGroup.Item>
  );
}
