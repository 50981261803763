import React from 'react'

import { CiCirclePlus } from "react-icons/ci";
import useDaynavStore from '../../data/Store';

import './AddButton.css'

function AddButton() {
    const [trip] = [useDaynavStore(state => state.trip)]

    const [addStep] = [useDaynavStore(state => state.addStep)]

    return (
        <div className="AutoAdd">
            <div className="add">
                <CiCirclePlus onClick={() => addStep(trip.steps.length-1)} />
            </div>
        </div>
    )
}
export default AddButton