import React from 'react'
import { SelectionType, StepModel } from '../../data/Data';
import useDaynavStore from '../../data/Store';
import StepDetail from '../detail/step/StepDetail';
import Photo from '../photo/Photo';

interface StepProps {
  step: StepModel;
  position: number;
  display: 'standard' | 'start' | 'end';
}

function Step(props: StepProps) {
  const [stepSelection, selectStep] = [useDaynavStore(state => state.stepSelection), useDaynavStore(state => state.selectStep)];

  const select = () => {
    if (stepSelection.step === props.position && stepSelection.type === SelectionType.Step) {
      selectStep({ step: -1, type: SelectionType.Step })
    } else {
      selectStep({ step: props.position, type: SelectionType.Step })
    }
  }

  const getClassName = (): string => {
    var className = 'schedule-item step ';
    if (props.position === stepSelection.step && stepSelection.type === SelectionType.Step) {
      className += "selected ";
    }
    className += props.display + " ";
    return className;
  }

  return (
    <>
      <div className={getClassName()}>
        <div className="lmgrid">

          <Photo step={props.step} position={props.position} onClick={select} />
          <div className="text" onClick={select}>
    
            <h4>Day {props.position+1} </h4>
            <h2>{props.step.name} </h2>
            <p>{props.step.description}</p>
            <ul>
              {props.step.activities?.map((activity, i) => {
                return <li>{activity}</li>
              })}
            </ul>
          </div>
          <div></div>

          <StepDetail step={props.step} position={props.position} selected={props.position === stepSelection.step && stepSelection.type === SelectionType.Step} />
        </div>
      </div>
    </>
  )
}

export default Step;