
const headers =  {
    "accept": "*/*",
    "accept-language": "en,en-US;q=0.9,de-DE;q=0.8,de;q=0.7",
    "cache-control": "no-cache",
    //"content-type": "application/x-www-form-urlencoded; charset=UTF-8",
    'Content-Type': 'application/json',
    "pragma": "no-cache"
};

const shareTrip = (trip, callback) => {
    let dataString = JSON.stringify(trip);    
    fetch(process.env.REACT_APP_API + '/api/Store?action=save', {
        "headers": headers,
        "referrerPolicy": "strict-origin-when-cross-origin",
        "method": "POST",
        "body": dataString,
        "mode": "cors",
        "credentials": "omit"
    }).then((response) => {
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json()}
    ).then((data) => {
        const url = process.env.REACT_APP_SHARE_URL + data.id + "/" + encodeURI(trip.name)
        callback(url);
    });
}


const loadTrip = (id, callback) => {
    fetch(process.env.REACT_APP_API + '/api/Store?action=get&id=' + id, {
        "headers": headers,
        "referrerPolicy": "strict-origin-when-cross-origin",
        "method": "GET",
        "mode": "cors",
        "credentials": "omit"
    }).then((response) => {
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json()}
    ).then((data) => {
        callback(data.elem.data);
    });
}


export {shareTrip, loadTrip};