import './App.css';
import TripEditor from './tripeditor/TripEditor';
import { Route, Routes } from 'react-router-dom';
import OpenShared from './OpenShared';
import CookieConsent from 'react-cookie-consent';
import Home from './home/Home';




function App() {
  return (
    <>    
    <div className="App">
      <Routes>
        <Route path="/trip/:id/:title" element={<OpenShared />} />
        <Route path="/trip/:id" element={<OpenShared />} />
        <Route path="/nav"  element={<TripEditor />} />
        <Route path="/"  element={<Home />} />

      </Routes>
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="daynavCs"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience. Local storage is required for functionality. 
      </CookieConsent>
    </div>
    </>
  );
}

export default App;
