import React, { useEffect } from 'react'
import { Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { loadTrip } from '../services/shareService';
import useDaynavStore from '../data/Store';
import { TripModel } from '../data/Data';

function OpenShared() {
    const navigate = useNavigate();
    const [load] = [useDaynavStore(state => state.load)];

    let { id } = useParams();
    useEffect(
        () => {    
            loadTrip(id, (trip: TripModel) => {
                console.log('open shared', id, trip);
                load(trip)
                navigate("/");
            })
        },
        [id, load, navigate],
    )

    return (
        <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    )
}
export default OpenShared;