import React, { useEffect, useState } from 'react'
import useDaynavStore from '../../data/Store';
import './detail.css'
import './WayDetail.css'
import { CiEdit } from "react-icons/ci";


export default function WayDetail(props) {
    const [step, position, selected] = [props.step, props.position, props.selected];
    const [edit, setEdit] = useState(false);

    useEffect(
      () => {    
        if (selected) {
          setEdit(false)
        }
      },
      [selected],
    )
  
    if (selected) {
        return (
            <div className="detail WayDetail">
              <div className="inner">
                <div className="buttons" >
                    <CiEdit onClick={() => setEdit(!edit)} />
                </div>
                
                
                <div className="text">
                  <WayDetailsContent step={step} position={position} edit={edit} setEdit={setEdit} />
                </div>
              </div>
            </div>
        )
    } 
    return (<div className="detail WayDetail"></div>)
      
}


function WayDetailsContent(props) {
    const [step, position] = [props.step, props.position];
    const [trip] = [useDaynavStore(state => state.trip)];

    


    if (position === 0) {
      return (
        <div className="styled">
          <dl>
              <dt>At</dt>
              <dd>{step.name}</dd>
          </dl>
        </div>
      )
    }

    return (
      <div className="styled">
        <dl>
            <dt>From</dt>
            <dd>{trip.steps[position-1]?.name}</dd>
            <dt>At</dt>
            <dd>{step.name}</dd>
            <dt>Transport</dt>
            <dd>Car</dd>
            <dt>Route</dt>
            <dd>{props.step.route?.summary}</dd>

        </dl>
  
      </div>
    )
}