import produce from 'immer'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { StepSelectionModel, TripModel, StepModel, SelectionType } from './Data'
import { AnyObject } from 'immer/dist/internal'

interface DaynavDataState {
  history: Array<TripModel>, 
  trip: TripModel, 
  stepSelection: StepSelectionModel, 
  assistantData: AnyObject
}

interface DaynavState extends DaynavDataState {
  setTrip: (val: TripModel) => void, 
  setAssistantData: (val: AnyObject) => void,
  addStep: (after: number) => void,
  selectStep: (val: StepSelectionModel) => void, 
  deleteStep: (pos: number) => void,
  moveStep: (from: number, to: number) => void, 
  updateStep: (position: number, data: StepModel) => void, 
  updateName: (name: string) => void, 
  reset: () => void, 
  createBlank: () => void, 
  load: (trip: TripModel) => void, 
  selectFromHistory: (pos: number) => void, 
}

const storeDefaults: DaynavDataState = {
    history: [], 
    trip: {
        name: '', 
        steps: []
    }, 
    stepSelection: {
        step: -1, 
        type: SelectionType.Step
    }, 
    assistantData: {}
}

const useDaynavStore = create<DaynavState>() (devtools(persist(
    (set) => ({
        history: storeDefaults.history, 
        trip: storeDefaults.trip, 
        stepSelection: storeDefaults.stepSelection, 
        assistantData: storeDefaults.assistantData,
        setTrip: (val) => set((state) => ({trip: val})), 
        addStep: (after) => set((state) => {
            return produce(state, (state) => {
                state.stepSelection = {step: after+1, type: SelectionType.Step}; 
                state.trip.steps.splice(after+1, 0, { name: 'new' });
            })
        }), 
        selectStep: (val) => set((state) => {
            if (val.step > state.trip.steps.length-1) return ({stepSelection: storeDefaults.stepSelection})
            return ({stepSelection: val})
        }),
        deleteStep: (pos) => set((state) => {
            return produce(state, (state) => {
                state.trip.steps.splice(pos, 1);
                if (pos < state.trip.steps.length) state.trip.steps[pos].route = null;
                if (pos + 1 < state.trip.steps.length) state.trip.steps[pos+1].route = null;
            })
        }),
        moveStep: (from, to) => set((state) => {
            state.selectStep({step: to, type: SelectionType.Step});
            return produce(state, (state) => {
                var element = state.trip.steps[from];
                state.trip.steps.splice(from, 1);
                state.trip.steps.splice(to, 0, element);
                state.trip.steps[to].route = null;
                if (to +1 < state.trip.steps.length) state.trip.steps[to+1].route = null;
                if (to +2 < state.trip.steps.length) state.trip.steps[to+2].route = null;
            })
        }),
        updateStep: (position, data) => set((state) => {
            console.log("updateStep", position, data);
            const oldStep = state.trip.steps[position];
            const steps = [...state.trip.steps] 
            steps[position] = data
            return produce(state, (state) => {
                state.trip.steps[position] = data
                if (oldStep && !oldStep.geometry && data.geometry && position +1 < state.trip.steps.length) {
                    state.trip.steps[position+1].route = null;
                }
            })
        }),
        updateName: (name) => set((state) => {
            return produce(state, (state) => {
                state.trip.name = name
            })
        }),
        reset: () => set(() => {
            return ({
                trip: storeDefaults.trip
            })
        }), 
        createBlank: () => set((state) => {
            return produce(state, (state) => {
                state.history.push(state.trip);
                state.stepSelection = storeDefaults.stepSelection;
                state.trip = storeDefaults.trip; 
            })
        }), 
        load: (trip) => set((state) => {
            return produce(state, (state) => {
                state.trip.name = "_" + state.trip.name
                state.history.push(state.trip);
                state.stepSelection = storeDefaults.stepSelection;
                state.trip = trip; 
            })
        }), 
        selectFromHistory: (pos) => set((state) => {
            var next = state.history[pos];
            return produce(state, (state) => {
                state.history.splice(pos, 1);
                state.history.push(state.trip);
                state.stepSelection = storeDefaults.stepSelection;
                state.trip = next;
            })
        }),
        setAssistantData: (val) => set((state) => ({assistantData: val}))
    }),
    {
        name: 'data-storage',
    }
)));


export default useDaynavStore