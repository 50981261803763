

const detailsMaxAge = 1000 * 60 * 60 * 24; /* ms */

/*
* Get place details per step
*/
const updateTripDetails = (map, trip, updateStep) => {
    trip.steps.forEach((step, i) => {
        if (step.details > Date.now() - detailsMaxAge || !step.place_id) return;
        var request = {
            placeId: step.place_id,
            fields: ['geometry', 'photos', 'rating', 'business_status', 'opening_hours', 'price_level', 'types', 'website']
        };
        const service = new window.google.maps.places.PlacesService(map);
        service.getDetails(request, (place) => {
            console.log('get details for ', i, step, place)
            var s = structuredClone(step)
            if (place.geometry) {
                s.geometry = {}
                if (place.geometry.location) {
                    s.geometry.location = [place.geometry.location.lat(), place.geometry.location.lng()];
                }
                if (place.geometry.viewport) {
                    s.geometry.viewport = place.geometry.viewport;
                }
                s.details = Date.now()
            }
            if (place.photos) {
                s.photos = place.photos.map(photo => photo.getUrl());
            }
            if (place.url) {
                s.url = place.url;
            }
            if (place.website) {
                s.website = place.website;
            }
            if (place.types) {
                s.types = place.types;
            }
            if (place.opening_hours) {
                s.opening_hours = {
                    periods: place.opening_hours.periods, 
                    weekday_text: place.opening_hours.weekday_text, 
                }
            }
            updateStep(i, s)
        });
    });
}

const toLatLng = (step) => {
    return { lat: step.geometry.location[0], lng: step.geometry.location[1] }
}

const routeToLatLngArr = (route) => {
    return route.overview_path.map(el => {return {lat: el.lat(), lng: el.lng()}})
}

const tripToBounds = (trip) => {
    var bounds = new window.google.maps.LatLngBounds();
    trip.steps.forEach(step => {
        if (step.geometry)
            bounds.extend(toLatLng(step));
    });
    return bounds;
}

const fixBounds = (bounds) => {
    if (bounds.Wa) {
        const nbounds = {
            south: bounds.Wa.lo,
            west: bounds.Ia.lo, 
            north: bounds.Wa.hi,
            east: bounds.Ia.hi,
        }
        bounds = nbounds;
    }
    return bounds;
}

const updateRoutes = (map, trip, updateStep) => {
    trip.steps.forEach((step, i) => {
        if (step.route || i === 0) return;
        var fromStep = trip.steps[i-1];
        var travelMode = 'DRIVING';
        if (fromStep && fromStep.geometry && step.geometry) {
            var request = {
                origin: toLatLng(fromStep), 
                destination: toLatLng(step), 
                travelMode: travelMode
              };
            var directionsService = new window.google.maps.DirectionsService();
            directionsService.route(request).then(result => {
                console.log('get route for ', i, step, result)
                if (result.status === 'OK') {
                    var distance = 0;
                    var duration = 0;
                    result.routes[0].legs.forEach(leg => {
                        distance += leg.distance.value;
                        duration += leg.duration.value;
                    })
                    var s = structuredClone(step)
                    s.route = {
                        path: routeToLatLngArr(result.routes[0]), 
                        distance, 
                        duration, 
                        summary: result.routes[0].summary, 
                        travelMode: travelMode, 
                        bounds: result.routes[0].bounds
                    }
                    updateStep(i, s)
                }
              });
        }
    });

}

const placeToStep = (place) => {
    return {
        name: place.value.structured_formatting.main_text, 
        description: place.value.description, 
        place_id: place.value.place_id
    }
}



const stepToLink = step => {
    return "https://www.google.com/maps/search/?api=1&query=" + step.name + "&query_place_id=" + step.place_id
}


export {updateTripDetails, toLatLng, updateRoutes, tripToBounds, placeToStep, fixBounds, stepToLink}