import React, { useState } from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { placeToStep } from '../../services/mapService'
import useDaynavStore from '../../data/Store'


import './Add.css'

import { Button, Form } from 'react-bootstrap';


export default function Add(props) {
    const [position] = [props.position]
    //const [trip] = [useDaynavStore(state => state.trip)]
    const [updateStep, deleteStep] = [useDaynavStore(state => state.updateStep), useDaynavStore(state => state.deleteStep)]

    const [place, setPlace] = useState();

    const submit = () => {
        var step = placeToStep(place);
        updateStep(position, step)
    }


    return (
        <div className="Add">
            <Form>
                <Form.Group className="mb-1" controlId="formPlace">
                    <GooglePlacesAutocomplete selectProps={{
                            onChange: setPlace
                        }}
                    />  
                </Form.Group>
                <Form.Group className="mb-1 addbuttons" controlId="buttons">
                    
                    <Button variant="secondary" onClick={() => deleteStep(position)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={submit} disabled={!place}>
                        Add
                    </Button>
                </Form.Group>
            </Form>
        </div>
    )
}
