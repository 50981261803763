import React, { useEffect, useState } from 'react'
import useDaynavStore from '../../../data/Store';
import { CiEdit, CiSquarePlus, CiSquareChevUp, CiTrash } from "react-icons/ci";

import '../detail.css'
import './StepDetail.css'
import { Button, Form } from 'react-bootstrap';

import Photos from './Photos';
import Links from './Links';
import Openings from './Openings';
import { StepModel } from '../../../data/Data';


interface StepDetailProps {
  step: StepModel,
  position: number,
  selected: boolean
}

export default function StepDetail(props: StepDetailProps) {
  const [step, position, selected] = [props.step, props.position, props.selected];
  const [addStep, deleteStep] = [useDaynavStore(state => state.addStep), useDaynavStore(state => state.deleteStep)]
  const [moveStep] = [useDaynavStore(state => state.moveStep)];

  const [edit, setEdit] = useState(false);

  const [delayed, setDelayed] = useState(-1);



  useEffect(
    () => {
      setDelayed(-1)
      if (selected) {
        setEdit(false)
      }
    },
    [selected],
  )

  const activateDelayed = (position: number, cb: Function) => {
    if (delayed === position) {
      setDelayed(-1)
      cb()
    } else {
      setDelayed(position)
      setTimeout(() => setDelayed(-1), 4000);
    }
  }

  if (selected) {
    return (
      <div className="detail StepDetail">
        <div className="inner">
          <div className="buttons" >
            <CiEdit onClick={() => setEdit(!edit)} />
            <CiSquarePlus onClick={() => addStep(props.position)} />
            <CiSquareChevUp className={delayed === 1 ? 'red' : ''} onClick={() => activateDelayed(1, () => moveStep(props.position, props.position - 1))} />
            <CiTrash className={delayed === 2 ? 'red' : ''} onClick={() => activateDelayed(2, () => deleteStep(props.position))} />
          </div>
          <div className="text">
            <StepDetailText step={step} position={position} edit={edit} setEdit={setEdit} />
          </div>
        </div>
      </div>
    )
  }
  return (<div className="detail StepDetail"></div>)

}

interface StepDetailTextProps {
  step: StepModel,
  position: number,
  edit: boolean,
  setEdit: Function
}

function StepDetailText(props: StepDetailTextProps) {
  const [step, position, edit, setEdit] = [props.step, props.position, props.edit, props.setEdit];
  const [text, setText] = useState(step.text);
  const [updateStep] = [useDaynavStore(state => state.updateStep)];

  const submit = () => {
    updateStep(position, { ...step, text: text });
    setEdit(false)
  }

  if (edit) {
    return (
      <>
        <Form.Control as="textarea" aria-label="Your notes" placeholder='Your notes' value={text || ""} onChange={e => setText(e.target.value)} />
        <div className="save"><Button size="sm" variant="secondary" onClick={() => setEdit(false)}>Cancel</Button><Button size="sm" onClick={submit}>Save</Button></div>
      </>

    )
  }
  return (
    <>
      <div className="styled">
        {step.text}
        <hr />
        <h1>{step.name}</h1>
        <Links step={step} />
        <Photos step={step} />
        <Openings step={step} />
      </div>
    </>
  )
}

